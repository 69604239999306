<template>
  <div>
    <v-card>
      <v-card-title class='px-2'>
        <Comeback />
        {{ $t('StockSummaryReport') }}
        <v-spacer></v-spacer>
        <v-btn
          color='primary'
          :loading='exportLoading'
          class='d-none d-md-block'
          :disabled='exportLoading'
          @click='exportExcel'
        >
          {{ $t('excel') }}
        </v-btn>
        <v-btn
          color='primary'
          class='d-block d-md-none'
          :loading='exportLoading'
          :disabled='exportLoading'
          icon
          fab
          outlined
          @click='exportExcel'
        >
          <v-icon>{{ mdiFileExcelOutline }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-row class='px-2'>
        <v-col
          cols='12'
          md='6'
          class='py-0'
          lg='3'
        >
          <v-autocomplete
            v-model.trim='lotSelect'
            :items='lotList'
            outlined
            dense
            :label="$t('lot')"
            item-text='druglot_name'
            :no-data-text="$t('no_information')"
            item-value='druglot_id'
            @change='addPayload'
          ></v-autocomplete>
        </v-col>
        <v-col
          cols='12'
          md='6'
          class='py-0'
          lg='3'
        >
          <v-select
            v-model.trim='drugCategorySelect'
            :items='drugCategoryList'
            :label="$t('category')"
            outlined
            dense
            :no-data-text="$t('no_information')"
            :item-text='$i18n.locale'
            item-value='value'
            @change='addPayload'
          ></v-select>
        </v-col>
        <v-col
          cols='12'
          md='6'
          class='py-0'
          lg='3'
        >
          <v-autocomplete
            v-model.trim='drugGroupSelect'
            :items='drugGroupList'
            outlined
            dense
            :label="$t('category')"
            :no-data-text="$t('no_information')"
            item-text='druggroup_name'
            item-value='druggroup_id'
            @change='addPayload'
          ></v-autocomplete>
        </v-col>
        <v-col
          cols='12'
          md='6'
          class='py-0'
          lg='3'
        >
          <v-autocomplete
            v-model.trim='drugTypeSelect'
            :items='drugTypeList'
            :label="$t('type')"
            outlined
            dense
            :no-data-text="$t('no_information')"
            item-text='drugtype_name'
            item-value='drugtype_id'
            @change='addPayload'
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-data-table
        :headers='columns'
        :items='dataTableList'
        :options.sync='options'
        :loading='loading'
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.drug_id`]='{ item }'>
          <router-link
            v-if='item.drug_id_pri'
            :to="{ name: 'drugDetail', params: { id: item.drug_id_pri } }"
            class='font-weight-medium text-decoration-none'
            target='_blank'
          >
            {{ item.drug_id }}
          </router-link>
        </template>
        <template v-slot:[`item.drugorder_status_id`]='{ item }'>
          <v-chip
            :color="item.drugorder_status_id == 1?'success':'error'"
            :class="item.drugorder_status_id==1?`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg success--text`:`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg error--text`"
            small
          >
            {{ item.drugorder_status_amount }}
          </v-chip>
        </template>
        <template v-slot:[`item.drugordert_total`]='{ item }'>
          <span :class="item.drugordert_total == $t('total') ? 'font-weight-bold primary--text':''">
            {{ item.drugordert_total }}
          </span>
        </template>
        <template v-slot:[`item.cost`]='{ item }'>
          <span :class="item.drugordert_total == $t('total') ? 'font-weight-bold primary--text':''">
            {{ item.cost }}
          </span>
        </template>
        <template v-slot:[`item.income`]='{ item }'>
          <span :class="item.drugordert_total == $t('total') ? 'font-weight-bold primary--text':''">
            {{ item.income }}
          </span>
        </template>
        <template v-slot:[`item.profit`]='{ item }'>
          <span :class="item.drugordert_total == $t('total') ? 'font-weight-bold primary--text':''">
            {{ item.profit }}
          </span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { mdiFileExcelOutline } from '@mdi/js'
import { i18n } from '@/plugins/i18n'
import Comeback from '../Comeback.vue'
import { reportLotDrug, removeComma } from '../useExcel'
import drugTypeForSearch from '@/@fake-db/data/drugTypeForSearch.json'
import stockAround from '@/api/DrugManagement/stockAround'
import { mdiCheck, mdiClose } from '@mdi/js'
import drugGroup from '@/api/DrugManagement/drugGroup'
import drugType from '@/api/DrugManagement/drugType'

export default {
  components: {
    Comeback,
  },
  setup() {
    const XLSX = require('xlsx')
    const lotSelect = ref('')
    const drugTypeSelect = ref('')
    const drugTypeList = ref([])
    const drugGroupList = ref([])
    const drugCategorySelect = ref('')
    const drugGroupSelect = ref('')
    const drugCategoryList = ref(drugTypeForSearch.data)
    const lotList = ref([])
    const payload = ref({})
    const loading = ref(false)
    const dataTableList = ref([])
    const options = ref({})
    const columns = ref([
      { text: '#', value: 'number', width: 50 },
      { text: i18n.t('drug_code'), value: 'drug_id', width: 80 },
      { text: i18n.t('drug_name'), value: 'drug_name', width: 150 },
      { text: i18n.t('lot_no'), value: 'drugorder_id', width: 150 },
      { text: i18n.t('lot'), value: 'druglot_name', width: 180 },
      {
        text: i18n.t('sale_price'), value: 'drug_price', width: 150, align: 'end',
      },
      {
        text: i18n.t('import'), value: 'drugorder_in', width: 150, align: 'end',
      },
      {
        text: i18n.t('suspend'), value: 'drugorder_status_id', align: 'end',
      },
      {
        text: i18n.t('transfer'), value: 'drugorder_out', width: 150, align: 'end',
      },
      {
        text: i18n.t('sell'), value: 'drugorder_sold', width: 150, align: 'end',
      },
      {
        text: i18n.t('balance'), value: 'drugordert_total', width: 150, align: 'end',
      },
      {
        text: i18n.t('cost'), value: 'cost', width: 150, align: 'end',
      },
      {
        text: i18n.t('income'), value: 'income', width: 150, align: 'end',
      },
      {
        text: i18n.t('profit'), value: 'profit', width: 150, align: 'end',
      },
    ])
    const exportLoading = ref(false)
    stockAround.stockAroundList({
      searchtext: '',
      druglot_status_id: '1',
    }).then(res => {
      lotList.value = res.data
      lotList.value.unshift({
        druglot_id: '',
        druglot_name: i18n.t('all_lot'),
      })
    })

    drugGroup.drugGroupList({
      searchtext: '',
      drug_category_id: '',
      druggroup_status_id: '1',
    }).then(res => {
      drugGroupList.value = res.data
      drugGroupList.value.unshift({
        druggroup_id: '',
        druggroup_name: i18n.t('all_categories'),
      })
    })

    drugType.drugTypeList({
      searchtext: '',
      drug_category_id: '',
      drugtype_status_id: '1',
    }).then(res => {
      drugTypeList.value = res.data
      drugTypeList.value.unshift({
        drugtype_id: '',
        drugtype_name: i18n.t('all_types'),
      })
    })

    const exportExcel = async () => {
      exportLoading.value = true
      let dataExport = dataTableList.value.map((item, i) => {
        delete item.drug_id_pri
        delete item.drugorder_status_id

        return { number: i + 1, ...item }
      })
      dataExport = await removeComma([...dataExport])
      const d = new Date()
      const d2 = `${d.getDay() < 10 ? `0${d.getDay()}` : d.getDay()}-${
        d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1
      }-${+d.getFullYear() + 543}`
      const fileName = `${i18n.t(
        'report_sum_drug_equipment',
      )} ${i18n.t('date')} ${d2}.xlsx`

      // เมื่อกดปุ่มจะทำการสร้างไฟล์ xcel ด้วย xlsx
      /*  รายงานวิเคราะห์รายการตรวจ */
      const Heading = [
        [
          `${i18n.t('report_sum_drug_equipment')} ${i18n.t(
            'date',
          )} ${d2} `,
        ],
        [
          '#',
          `${i18n.t('drug_code')}`,
          `${i18n.t('drug_name')}`,
          `${i18n.t('lot_no')}`,
          `${i18n.t('lot')}`,
          `${i18n.t('cost_unit')}`,
          `${i18n.t('sale_price')}`,
          `${i18n.t('import')}`,
          `${i18n.t('suspend')}`,
          `${i18n.t('transfer')}`,
          `${i18n.t('sell')}`,
          `${i18n.t('balance')}`,
          `${i18n.t('cost')}`,
          `${i18n.t('income')}`,
          `${i18n.t('profit')}`,
        ],
      ]

      const ws = XLSX.utils.aoa_to_sheet(Heading)
      XLSX.utils.sheet_add_json(ws, dataExport, {
        header: [
          'number',
          'drug_id',
          'drug_name',
          'drugorder_id',
          'druglot_name',
          'drugorder_cost',
          'drug_price',
          'drugorder_in',
          'drugorder_status_amount',
          'drugorder_out',
          'drugorder_sold',
          'drugordert_total',
          'cost',
          'income',
          'profit',
        ],
        skipHeader: true,
        origin: -1,
      })
      const wb = XLSX.utils.book_new()
      const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 13 } }]
      const wscols = [
        { wch: 6 },
        { wch: 8 },
        { wch: 15 },
        { wch: 15 },
        { wch: 25 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 15 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
      ]
      ws['!merges'] = merge
      ws['!cols'] = wscols
      XLSX.utils.book_append_sheet(wb, ws, `${i18n.t('report_sum_drug_equipment')}`.replace('/', '-'))

      /* พิมร์ files */
      XLSX.writeFile(wb, fileName)
      setTimeout(() => {
        exportLoading.value = false
      }, 1500)
    }

    onMounted(() => {
      addPayload()
    })

    const addPayload = () => {
      payload.value = {
        druglot_id: lotSelect.value,
        drug_category_id: drugCategorySelect.value,
        druggroup_id: drugGroupSelect.value,
        drugtype_id: drugTypeSelect.value,
        lang: i18n.locale,
      }
      serachReport(payload.value)
    }

    const serachReport = async payload => {
      loading.value = true
      const { data } = await reportLotDrug(payload)
      dataTableList.value = data
      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    }

    return {
      exportExcel,
      lotSelect,
      drugCategorySelect,
      drugTypeSelect,
      drugTypeList,
      drugCategoryList,
      drugGroupList,
      drugGroupSelect,
      lotList,
      dataTableList,
      exportLoading,
      icons: {
        mdiCheck,
        mdiClose,
      },
      options,
      loading,
      columns,
      mdiFileExcelOutline,
      addPayload,
    }
  },
}
</script>
